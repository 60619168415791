#mc_embed_signup {
  padding-bottom: 4rem;
}

label {
  line-height: 4rem;
  padding-right: 2rem;
}

#mc-embedded-subscribe {
  color: white;
  background-color: #333;
  margin-top: 3rem;
  padding: 1rem;
}
